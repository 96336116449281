
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import TextArea from "primevue/textarea";
import Calendar from "primevue/calendar";
import CheckBox from "primevue/checkbox";
import Image from "primevue/image";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Divider from "primevue/divider";
import Row from "primevue/row";
import ColumnGroup from "primevue/columngroup";
import ProgressSpinner from "primevue/progressspinner";
import { NewItemShipment, NewItemSched } from "@/types/state/SalesDetailItem";
import {
  LisItems,
  LiSchedDatesItems,
  LiShipNosItems,
  LiRepsItems,
} from "@/types/salesorder";
import AddWorkOrderDialog from "@/components/Sales/Orders/AddWorkOrderDialog.vue";
import AddRepDialog from "@/components/Sales/Orders/AddRepDialog.vue";
import AddShipmentDialog from "@/components/Sales/Orders/AddShipmentDialog.vue";
import AddSchedDialog from "@/components/Sales/Orders/AddSchedDialog.vue";
import LookupPartNumber from "@/components/Sales/LineItems/LookupPartNumber.vue";
import LookupSingleLevel from "@/components/Sales/LineItems/LookupSingleLevel.vue";
import LookupMultiLevel from "@/components/Sales/LineItems/LookupMultiLevel.vue";
import Utils from "@/utility/utils";
import PartsService from "@/services/PartsService";
import CustomFields from "@/components/UI/CustomFields.vue";

import { mapGetters } from "vuex";
import { Fdict } from "@/types/fdict";

const partService = new PartsService();

export default defineComponent({
  name: "LineItem",
  components: {
    Card,
    InputText,
    Button,
    TextArea,
    Calendar,
    CheckBox,
    Image,
    DataTable,
    Column,
    ColumnGroup,
    Row,
    AddWorkOrderDialog,
    AddRepDialog,
    AddShipmentDialog,
    AddSchedDialog,
    Divider,
    ProgressSpinner,
    LookupPartNumber,
    LookupSingleLevel,
    LookupMultiLevel,
    CustomFields,
  },
  computed: {
    ...mapGetters({
      getActiveLineItem: "salesInquiry/getActiveLineItemsTab",
      getOpenedLineItem: "salesInquiry/getOpenedLineItem",
      getClient: "session/getClient",
    }),
    schedQtyTotal(): string {
      return Utils.reduceTotal(
        this.currentItem.li_sched_dates_items,
        "li_sched_qtys",
      )
        .toFixed(2)
        .toString();
    },

    schedRemainingTotal(): string {
      return Utils.reduceTotal(
        this.currentItem.li_sched_dates_items,
        "remaining",
      )
        .toFixed(2)
        .toString();
    },

    shipmentsQtyTotal(): string {
      return Utils.reduceTotal(
        this.currentItem.li_ship_nos_items,
        "li_ship_qtys",
      )
        .toFixed(2)
        .toString();
    },
    customFields(): any[] {
      if (this.salesOrderFdict.field_no_items) {
        return this.salesOrderFdict.field_no_items.filter((item) => {
          return (
            ((+item.field_no >= 80 && +item.field_no <= 100) ||
              item.web_required === "Y") &&
            item.control_field == "26"
          );
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions({
      addRepToOrder: "salesInquiry/addRepToOrder",
      removeRepFromOrder: "salesInquiry/removeRepFromOrder",
      getFdicts: "fdict/fetchFdict",
    }),
    updateCustomFields(data: any) {
      this.currentItem.custom_fields = data;
    },
    closeLookupDialog(dialog: string) {
      if (dialog === "partNumber") {
        this.showLookupPartNumberDialog = false;
      } else if (dialog === "singleLevel") {
        this.showLookupSingleLevelDialog = false;
      } else if (dialog === "multiLevel") {
        this.showLookupMultiLevelDialog = false;
      }
    },
    openLookupDialog(dialog: string) {
      if (dialog === "partNumber") {
        this.showLookupPartNumberDialog = true;
      } else if (dialog === "singleLevel") {
        this.showLookupSingleLevelDialog = true;
      } else if (dialog === "multiLevel") {
        this.showLookupMultiLevelDialog = true;
      }
    },

    deleteSchedule(index: number) {
      this.currentItem.li_sched_dates_items.splice(index, 1);
    },

    deleteRepresentatives(index: any) {
      // this.removeRepFromOrder({orderId: this.currentOrderId, rep: this.currentItem.li_reps_items[index]})
      this.currentItem.li_reps_items.splice(index, 1);
    },

    deleteShipment(id: string | number) {
      if (this.currentItem.li_ship_nos_items.length > 0) {
        this.currentItem.li_ship_nos_items =
          this.currentItem.li_ship_nos_items.filter(
            (element: any) => element.li_ship_nos !== id,
          );
      }
    },

    addRepresentative(newRepresentative: any) {
      if (
        !this.currentItem.li_reps_items ||
        this.currentItem.li_reps_items.length === 0
      ) {
        this.currentItem.li_reps_items = [];
      }
      this.currentItem.li_reps_items.push({
        li_reps: newRepresentative.li_reps,
        li_comms: newRepresentative.li_comms,
      } as unknown as LiRepsItems);
      // const rep = {
      //   rep: newRepresentative.li_reps,
      //   order_pct: newRepresentative.li_comms,
      //   rep_name: newRepresentative.rep_name
      // }
      // this.addRepToOrder({orderId: this.currentOrderId, rep: {...rep}})
    },

    addShipment(newShipment: NewItemShipment) {
      let nextId = 1;
      if (this.currentItem.li_ship_nos_items.length !== 0) {
        nextId = this.currentItem.li_ship_nos_items.length + 1;
      }
      this.currentItem.li_ship_nos_items.push({
        ...newShipment,
        li_ship_nos: nextId.toString(),
      } as unknown as LiShipNosItems);
    },

    addSched(newSched: NewItemSched) {
      if (this.currentItem.li_sched_dates_items.length === 0) {
        this.currentItem.li_sched_dates_items = [];
      }
      this.currentItem.li_sched_dates_items.push({
        ...newSched,
      } as unknown as LiSchedDatesItems);
    },

    loadData() {
      if (this.$route.params.orderId) {
        this.currentOrderId = this.$route.params.orderId as string;
        this.currentLineItemId = this.$route.params.itemId as string;
        this.currentItem = this.getOpenedLineItem(
          this.currentOrderId,
          this.currentLineItemId,
        ) as LisItems;

        // CO 3/4/24 - Removing the editable check for now
        this.editable = true;
      }
      this.isLoadingImages = true;
      partService
        .getPart(this.getClient, this.currentItem.li_parts)
        .then((response: any) => {
          if (
            response.image_desc_items &&
            response.image_desc_items.length > 0
          ) {
            const image = response.image_desc_items[0];
            this.web_image_desc = image.image_desc || "";
            this.web_image_path = image.image_path || "";
          } else {
            this.web_image_path = response.web_image_path ?? "";
            this.web_image_desc =
              response.web_information ?? "Image not available";
          }
        })
        .finally(() => {
          this.isLoadingImages = false;
        });
    },
  },

  data: () => ({
    showLookupMultiLevelDialog: false,
    showLookupSingleLevelDialog: false,
    showLookupPartNumberDialog: false,
    currentLineItemId: "",
    currentOrderId: "",
    currentItem: {} as LisItems,
    salesOrderFdict: {} as Fdict,
    editable: false,
    web_image_path: "",
    web_image_desc: "",
    isLoadingImages: false,
    responsiveOptions: [
      {
        breakpoint: "991px",
        numVisible: 4,
      },
      {
        breakpoint: "767px",
        numVisible: 3,
      },
    ],
  }),
  async created() {
    this.loadData();
    this.salesOrderFdict = (await this.getFdicts("SO")) as Fdict;
  },
  watch: {
    $route(to, from) {
      if (to.fullPath && to.fullPath.includes("line-item")) {
        this.loadData();
      }
    },
  },
});
