
import { defineComponent } from "vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";

export default defineComponent({
  name: "AddWorkOrderDialog",
  components: {
    Button,
    Dialog,
    InputText,
  },

  emits: ["addWorkOrder"],

  methods: {
    addRecord() {
      this.$emit("addWorkOrder", this.newOrder);
      this.visible = false;
      this.newOrder = "";
    },

    cancel() {
      this.visible = false;
      this.newOrder = "";
    },
  },

  data() {
    return {
      visible: false,
      newOrder: "",
    };
  },
});
