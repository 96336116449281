import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex p-0 m-0 justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    header: _ctx.getTitle,
    modal: true,
    draggable: false,
    closable: false,
    class: "p-fluid text-center w-11 lg:w-8 xl:w-6 lkp-multi-lvl-dialog"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, { onClick: _ctx.handleClose }, {
        default: _withCtx(() => [
          _createTextVNode("Close")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
              key: 0,
              class: "col-12"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}