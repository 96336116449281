
import { defineComponent } from "vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import CheckBox from "primevue/checkbox";
import Calendar from "primevue/calendar";
import Utils from "@/utility/utils";

export default defineComponent({
  name: "AddScheduleDialog",
  components: {
    Button,
    Dialog,
    CheckBox,
    InputNumber,
    Calendar,
    InputText,
  },

  emits: ["addSched"],

  methods: {
    addRecord() {
      this.$emit("addSched", {
        ...this.newSched,
        remaining: this.newSched.remaining.toString(),
        li_sched_qtys: this.newSched.li_sched_qtys.toString(),
      });
      this.visible = false;
      this.newSched = {
        li_sched_dates: "",
        remaining: 0,
        li_sched_qtys: 0,
        cmt: "",
        original: "",
      };
    },

    cancel() {
      this.visible = false;
      this.newSched = {
        li_sched_dates: "",
        remaining: 0,
        li_sched_qtys: 0,
        cmt: "",
        original: "",
      };
    },

    formatDate() {
      this.newSched.li_sched_dates = Utils.formatDate(
        this.newSched.li_sched_dates as string,
      );
    },
  },

  data() {
    return {
      visible: false,
      newSched: {
        li_sched_dates: "",
        remaining: 0,
        li_sched_qtys: 0,
        cmt: "",
        original: "",
      },
    };
  },
});
