
import { defineComponent } from "vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputNumber from "primevue/inputnumber";
import CheckBox from "primevue/checkbox";
import Calendar from "primevue/calendar";
import Utils from "@/utility/utils";

export default defineComponent({
  name: "AddShipmentDialog",
  components: {
    Button,
    Dialog,
    CheckBox,
    InputNumber,
    Calendar,
  },

  emits: ["addShipment"],

  methods: {
    addRecord() {
      this.$emit("addShipment", this.newShipment);
      this.visible = false;
      this.newShipment = {
        date: "",
        num: 0,
        qty: 0,
        open: "",
      };
    },

    cancel() {
      this.visible = false;
      this.newShipment = {
        date: "",
        num: 0,
        qty: 0,
        open: "",
      };
    },

    formatDate() {
      this.newShipment.date = Utils.formatDate(this.newShipment.date as string);
    },
  },

  data() {
    return {
      visible: false,
      newShipment: {
        date: "",
        num: 0,
        qty: 0,
        open: "",
      },
    };
  },
});
